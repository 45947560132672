<template>
	<v-sheet class="recurring-main-listing" style="min-height: calc(100vh - 120px)">
		<v-layout class="">
			<v-flex class="py-0 d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<div class="d-flex py-3">
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon left>mdi-chevron-down</v-icon>
									Bulk Action
								</v-btn>
							</template>
							<v-list class="py-0">
								<template v-for="(item, i) in listingBulkAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							v-on:click="clearSelection"
							color="red lighten-1"
							class="text-white"
						>
							<v-icon left> mdi-close </v-icon>
							Clear Selections
						</v-btn>
					</div>
				</template>
				<template v-else>
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="project_status_list"
						v-on:change="statusFilterRows($event)"
						solo
					>
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
									<span>{{ item.status_count }}</span>
								</v-avatar>
							</v-list-item-action>
						</template>
					</v-select>
					<v-flex class="d-flex align-center justify-content-end pr-1">
						<div class="mr-2 fw-600">Filter By</div>
						<div class="ml-3">
							<v-autocomplete
								style="max-width=50% !important;"
								:items="modules"
								clearable
								item-text="text"
								:disabled="pageLoading"
								item-value="value"
								v-model="module"
								v-on:click:clear="(defaultFilter = {}), getListing()"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								placeholder="Module"
								v-on:change="commanFilter($event, 'module')"
							>
							</v-autocomplete>
						</div>
						<v-btn
							depressed
							color="white"
							class="red darken-4"
							tile
							text
							small
							style="height: 26px; min-width: 0px !important"
							v-on:click="clearFliter"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-flex>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white my-auto"
						tile
						v-on:click="summary_show = !summary_show"
						:disabled="pageLoading"
					>
						<v-icon>mdi-chart-box-outline</v-icon>
					</v-btn>
					<v-menu
						v-if="internaldata == false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
						class="my-auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white my-auto"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group my-auto"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('customer')"
						>
							<template v-for="cols in draggableThead" class="my-auto">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('notification')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary" :class="summary_show ? 'show' : ''">
			<v-flex
				class="summary-details bg-light-blue"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('all')"
			>
				<div class="name text-capitalize">All Notifications</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="blue">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number blue--text ms-2 mt-2">
						{{ count.all }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-d-cyan"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows(1)"
			>
				<div class="name text-capitalize">Read</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="cyan">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number cyan--text ms-2 mt-2">
						{{ count.read }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-green"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows(3)"
			>
				<div class="name text-capitalize">Unread</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="green">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number green--text ms-2 mt-2">
						{{ count.unread }}
					</div>
				</div>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
				style="border: 2px solid red"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Notification #"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>

		<AdvanceFilter
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="all-notifications"
			search-title="Notifications"
		></AdvanceFilter>

		<template>
			<Table
				type="notification"
				delete-endpoint="notification/"
				detail-route="notification-detail"
				update-route="update-notification"
				v-on:reload:content="filterRows"
				delete-note="All transactions of this member will also be deleted."
			></Table>
		</template>
	</v-sheet>
</template>

<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
.v-application .v-list.v-sheet .v-list-item .v-list-item__title {
	text-transform: capitalize !important;
}
</style>
<script>
import Dialog from "@/view/components/Dialog";
import ListingMixin from "@/core/mixins/listing.mixin";
//import UpdateInvoice from "@/view/module/components/update-invoice";
//import DatePicker from "@/view/components/DatePicker";
//import Contact from "@/view/module/contract/Create-Contract";
/* import CreateProject from "@/view/module/project/CreateProject.vue"; */
// import { ProjectEventBus } from "@/core/lib/leave.form.lib";
import { toSafeInteger } from "lodash";
import { GET, QUERY } from "@/core/services/store/request.module";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";

import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";

export default {
	name: "Contract-listing",
	title: "Listing Contract",
	mixins: [ListingMixin],
	data() {
		return {
			export_dialog: false,
			projectDialog: false,
			singleData: {},
			customers: [],
			users: [],
			modules: [],
			module: null,
			projectId: 0,
			urlsDialog: false,
			summary_show: false,
			search_query: null,
			actula_date_filter: null,
			drawerFilter: false,
			pageTitle: "Notifications",
			user_type: 0,
			customer_type: 0,
			pageBreadcrumbs: [{ text: "Notifications", disabled: true }],
			endpoint: "all-notifications",
			defaultFilter: {},
			count: {},
			pageLoading: false,
			billingType: [
				{ value: "single_billing", text: "Single Billing" },
				{ value: "multiple_billing", text: "Multiple Billing" },
			],
			DataSearch: null,
			project_status_list: [],
			status: "all",
			/* 		addvanceFilterAarry: [], */
			ptype: "project-types",
		};
	},
	props: {
		relatedId: {
			type: Number,
			default: 0,
		},
		internaldata: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Boolean,
			default: false,
		},
		/*  customerId: {
			type: Number,
			default: 0,
		}, */
	},
	watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	},
	methods: {
		referess() {
			window.location.reload();
		},
		create_project() {
			this.projectDialog = true;
		},
		getProjectdata(id) {
			this.projectDialog = true;
			this.projectId = id;
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "project",
				},
			});
		},

		statusFilterRows(value) {
			//	this.allFiltersStatus(value);
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `all-notifications`,
					data: {
						status: value,
						search: this.DataSearch,
						related_id: this.relatedId,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					//this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		commanFilter(event, module) {
			this.pageLoading = true;
			//console.log(event);
			let url = `all-notifications`;
			if (module == "module") {
				url = `all-notifications/?module=` + event;
			}
			//this.allFiltersSetting();
			this.$store
				.dispatch(QUERY, {
					url: url,
					data: {
						//user_type: this.user_type,
						//customer_type: this.customer_type,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersSetting() {
			this.$router.push({
				name: "all-notifications",
				query: {
					user_type: this.user_type ? this.user_type : null,
					customer_type: this.customer_type ? this.customer_type : null,
					t: new Date().getTime(),
				},
			});
		},
		clearFliter() {
			this.module = null;
			this.customer_type = null;
			this.user_type = null;
			this.defaultFilter = {};
			this.$nextTick(() => {
				this.getListing();
			});
			this.$router.replace({
				name: "notifications",
				query: { t: new Date().getTime() },
			});
		},
		getProjectCount() {
			this.$store
				.dispatch(QUERY, {
					url: `notification-count`,
				})
				.then((data) => {
					this.count = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getProjectSetting() {
			this.$store
				.dispatch(GET, { url: "notification-setting" })
				.then((data) => {
					// this.customers = data.customers;
					// this.users = data.users;
					this.project_status_list = data.status;
					this.modules = data.modules;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "project",
				query: {
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		/* getprojecttypelist() {
			this.$store
				.dispatch(GET, { url: "project-typelist" })
				.then((data) => {
					this.projecttypeList = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		/* getprojectstatuslist() {
			this.$store
				.dispatch(GET, { url: "project-status-list" })
				.then((data) => {
					this.project_status_list = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	components: {
		//RecurringKanban,
		Dialog,
		//UpdateInvoice,
		/* CreateProject, */
		//DatePicker,
		// Contact
		AdvanceFilter,
	},
	beforeMount() {
		this.user_type = toSafeInteger(this.$route.query.user_type);
		this.customer_type = toSafeInteger(this.$route.query.customer_type);
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_type) {
			this.user_type = this.currentUser.id;
		}
		this.defaultFilter = {
			user_type: this.user_type ? this.user_type : null,
			customer_type: this.customer_type ? this.customer_type : null,
		};
	},
	mounted() {
		/* 	this.getprojectstatuslist(); */
		//this.getprojecttypelist();
		//this.getRecurringStatus();
		//this.getInvoiceCount();
		/* this.getListing(); */

		this.getProjectCount();
		this.getProjectSetting();
		let filterQuery = this.$route.query;
		this.$router.push({
			name: "notifications",
			query: {
				...filterQuery,
				t: new Date().getTime(),
			},
		});

		/* if (this.currentUser.role == 1) {
			this.user_type = this.currentUser.id;
		} */
		/* ProjectEventBus.$on("updateData", () => {
			this.getListing();
		}); */
	},
};
</script>
